.backdrop {
  height: 100vmax;
  width: 100vmax;
  border-radius: 100%;
  left: 50%;
  top: 50%;
}

.success {
  @apply bg-primary;
}

.icon {
  width: 75px;
  height: 75px;
  padding-top: 5px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  margin-top: -37.5px;

  &.success {
    @apply bg-green-600;
  }

  &.default {
    @apply bg-primary-dark;
  }
}
