.select-wrapper {
  @apply relative;

  .lucky {
    @apply rounded-md text-white;
    box-shadow:
      0 2px 5px 0 var(--primary-shade),
      inset 0 1px 0 0 rgba(255, 255, 255, 0.19);
    background: linear-gradient(
      to bottom,
      var(--primary-light),
      var(--primary)
    );
    option {
      @apply bg-primary-light text-white;
    }
  }

  select {
    @apply h-12 rounded-md border-0 bg-white pl-3 pr-2 text-base uppercase text-black outline-none focus:outline-none;
    &:focus {
      background: linear-gradient(to bottom, #7a389630, #6624823b);
    }
  }

  svg {
    @apply absolute;
    top: calc(24px - 8px);
  }
}
