.currency-input {
  &::placeholder {
    @apply text-xl font-semibold uppercase text-black/30 opacity-90;
    letter-spacing: 0.17px;
  }
}

.clearBtn {
  @apply absolute right-0 cursor-pointer text-lg underline;
  top: -4px;
  opacity: 0.56;
  font-weight: 600;
  letter-spacing: 0.14px;
  text-align: right;
  color: #fff;
}
