.limits-wrapper {
  @apply mx-auto mt-6 flex w-full max-w-lg flex-col items-center justify-center rounded-xl text-white shadow-limits-container;

  .limits-header {
    @apply mb-4 ml-3.5 mr-3 mt-9 text-center text-2xl font-semibold uppercase;
  }

  .no-limits-description {
    @apply mx-0 mb-7 mt-4 text-center text-lg font-semibold opacity-30;
  }

  .set-limit-btn {
    @apply text-base;
    width: 214px;
    padding: 12px 31px;
    border-radius: 9px;
    box-shadow:
      0 2px 5px 0 var(--primary-shade),
      inset 0 1px 0 0 rgba(255, 255, 255, 0.19);
    background-image: linear-gradient(
      to bottom,
      var(--primary-light),
      var(--primary)
    );
    color: #fff;
  }
}

.set-limit-btn {
  @apply w-56 rounded-md bg-gradient-to-b from-primary-light to-primary py-3 text-base text-white shadow-purpleShadow;
}

.clear-limits {
  @apply mr-1.5 h-1.5 w-1.5;
  .clear-limits {
    @apply mr-1.5;
    width: 6px;
    height: 6px;

    &-txt {
      @apply text-base font-semibold uppercase;
      letter-spacing: 0.13px;
      text-align: center;
      color: #fff;
    }
  }
}
