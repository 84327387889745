.format-declaration {
    a {
      @apply underline text-white font-bold;
    }
  
    table {
      display: block;
      overflow-x: auto;
  
      td {
        padding: 0px 8px;
      }
  
      td:nth-child(3) {
        min-width: 250px;
      }
    }
  }
  