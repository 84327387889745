.headingName {
  @apply m-0 uppercase opacity-100;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.27px;
  text-align: center;
  color: #fff;
}

.headingAddress {
  @apply m-0 uppercase;
  opacity: 0.77;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.15px;
  text-align: center;
  color: #fff;
}
