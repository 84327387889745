@use 'sass:color';
@use '../../../styles/variables';

.Secondary {
  @apply my-16 flex flex-col text-black;

  background: linear-gradient(
    to bottom left,
    variables.$secondary 50%,
    color.mix(variables.$secondary, variables.$secondary-dark, $weight: 50%) 50%
  );

  h2 {
    @apply pt-8 uppercase;
  }

  &:before {
    content: '';
    width: auto;
    height: 64px;
    margin-top: -64px;
    background: linear-gradient(
      to bottom right,
      variables.$primary 50%,
      variables.$secondary calc(50% + 1px)
    );
  }

  &:after {
    content: '';
    width: auto;
    height: 64px;
    margin-bottom: -64px;
    background: linear-gradient(
      to bottom right,
      color.mix(variables.$secondary, variables.$secondary-dark, $weight: 50%) 50%,
      variables.$secondary calc(50% + 1px)
    );
  }
}

.slicePr {
  @apply mb-8 flex flex-col pb-8 pt-16 text-black;
  background-color: variables.$secondary;

  &:after {
    content: '';
    width: 100%;
    height: 64px;
    margin-bottom: -64px;
    background: linear-gradient(
      to bottom right,
      variables.$secondary 50%,
      variables.$primary calc(50% + 1px)
    );
  }

  & .fancyTitle {
    & h2 {
      &:before {
        background-image: linear-gradient(
          0.75turn,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
      &:after {
        background-image: linear-gradient(
          0.25turn,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
  }
}

.fancyTitle {
  @apply mx-auto mb-10 max-w-7xl text-center uppercase;
  & h2 {
    @apply flex;

    &:before,
    &:after {
      @apply m-auto h-1 w-full bg-white opacity-40;
      content: '';
      flex: 1;
    }

    &:before {
      @apply mr-8;
      background: linear-gradient(
        0.75turn,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    &:after {
      @apply ml-8;
      background: linear-gradient(
        0.25turn,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}
