.bg {
  opacity: 60%;
  background: linear-gradient(
    180deg,
    var(--primary-shade) 0%,
    color-mix(in srgb, var(--primary-shade) 80%, transparent) 70%,
    rgba(0, 0, 0, 0) 100%
  );
}

.bgExpandedSolid {
  height: 8.25rem;
  background: linear-gradient(
    180deg,
    var(--primary-shade) 0%,
    var(--primary-shade) 80%,
    color-mix(in srgb, var(--primary-shade) 60%, transparent) 90%,
    rgba(0, 0, 0, 0) 100%
  );

  @media screen and (max-width: 768px) {
    height: 8rem;
    background: linear-gradient(
      180deg,
      var(--primary-shade) 0%,
      var(--primary-shade) 72%,
      color-mix(in srgb, var(--primary-shade) 60%, transparent) 90%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.bgExpandedOpacity {
  height: 9rem;
  opacity: 66%;
  background: linear-gradient(
                  180deg,
                  var(--primary-shade) 0%,
                  var(--primary-shade) 60%,
                  rgba(0, 0, 0, 0) 100%
  );

  @media screen and (max-width: 768px) {
    height: 8rem;
  }
}
