.root {
  @apply flex h-0 -translate-y-full items-center justify-center overflow-hidden bg-error text-sm text-white opacity-0;
  border-radius: 0 0 5px 5px;
  &.standalone {
    @apply rounded-md text-center;
  }
}

.startTransiction {
  @apply h-full translate-y-0 transform opacity-100 transition delay-75 motion-reduce:transition-none;
  max-height: 70px;
}

.text {
  @apply px-4 py-1 normal-case text-base;
}
