@use '../../../styles/variables';

.light {
  @apply rounded-md bg-white text-left font-semibold text-black;
  letter-spacing: 0.23px;
  box-shadow: 0 0 25px 0 var(--primary-mid);
  &::placeholder {
    @apply text-black/30;
  }
}

.dark {
  @apply bg-primary-dark text-left font-semibold text-white focus:ring-primary-light;
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.2);
  &::placeholder {
    @apply text-white opacity-40;
  }
  &::-webkit-search-cancel-button {
    filter: invert(1);
  }
}

.error {
  @apply rounded-b-none rounded-t-md border border-solid border-error bg-error;
  input {
    @apply bg-white text-black shadow-none;
  }
}

.disabled {
  @apply opacity-70;
}
.verified {
  @apply absolute text-lg text-primary;
  top: calc(50% - 9px);
  right: 7px;
}

.password {
  @apply absolute bottom-4 right-4 text-gray-400;
  transition: all 0.2s ease;
}

.showPassword {
  @apply scale-125 text-primary;
}

.label2 {
  @apply mb-1 ml-1 flex items-center justify-center uppercase;
  font-size: 8px;
  height: 14px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.5);
  padding: 2px 4px 2px 5px;
}

.noFocusBorder input {
  box-shadow: none !important;
}
