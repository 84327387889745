.clock-back {
  z-index: 1;
  width: 23px;
  height: 23px;
  top: calc(50% - 11px);
  left: 14px;
  path:first-of-type {
    fill: var(--primary-light);
  }
}

.arrow-counter-clock {
  z-index: 2;
  transform: rotate(-60deg);
}
