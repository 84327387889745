.root {
  @apply flex items-center justify-center border-none uppercase;
}

.backBtnTxt {
  @apply ml-0.5 mr-3 text-base font-semibold;
}

.light {
  @apply bg-white/20 text-white;
}

.dark {
  @apply bg-primary-dark text-white;
}
