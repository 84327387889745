.footerGradiant {
  @apply mb-6 h-12;
  background-image: linear-gradient(
    to bottom,
    var(--primary) 10%,
    var(--primary-dark) 90%
  );
}

.greyBorder {
  @apply border-y border-solid border-white/10;
}

.footerLinksContainer {
  @apply mb-8 flex flex-wrap justify-center md:mb-12;
}

.footerLinks {
  @apply select-none py-2.5 pl-3 text-lg;
  letter-spacing: 0.17px;

  &:not(:last-child):after {
    @apply ml-3 border-r-2 border-solid border-white;
    content: '';
  }
}

.imageOverlay {
  @apply absolute inset-3 bottom-5 bg-fuchsia-400 opacity-70 blur-xl;
  content: '';
}

.footerImagesPayment {
  @media (max-width: 430px) and (max-height: 800px) {
    @apply mt-2;
  }
}

.footerText {
  @apply mb-12 flex flex-col items-center text-center text-sm font-semibold text-white/75;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.15px;
}
