.depositLimit {
  display: flex;
  flex-direction: column;
  align-items: center;
  > input {
    margin-bottom: 5px;
    -moz-appearance: textfield;
  }
  > button {
    width: 100%;
  }
}

.verifyCode input {
  -moz-appearance: textfield;
  letter-spacing: 6px;
  padding: 0 0 0 6px;
  text-align: center;
  width: 110px;

  &::placeholder {
    text-align: center;
  }
}

.menuItem {
  border-bottom: 1px solid var(--primary-dark);
}

.limitsInfo {
  font-size: 0.72rem;
  text-transform: uppercase;
  color: #e5e5e5;
  text-align: center;
}

.OnbordingErrorWrapper {
  display: flex;
  flex-direction: column;
}

.OnbordingErrorWrapper > div {
  margin: 4px 0 20px 0;
}

.onboardingInput {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.termsAndConditionsWrapper {
  border-radius: 10px;
  margin-top: 15px;
  height: 50vh;
  overflow-y: scroll;
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
}

.OnboardingBonusImage {
  width: 180px;
  overflow: hidden;
  margin: 40px auto 70px auto;
}

.gamingLimitWrapper {
  @apply w-full uppercase;
  .limitWrapper {
    @apply my-1 flex;
    span {
      width: 49%;
    }

    span:first-child {
      @apply text-right;
      margin-right: 3px;
    }
    span:last-child {
      @apply text-left;
      margin-left: 3px;
    }
  }
}

.completeDepositBtn {
  @apply text-xl;
  font-weight: bold;
  letter-spacing: 0.18px;
  height: 62px;
}

.transactionBtn {
  @apply text-base;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.documentUploadFrame {
  width: 100%;
  height: calc(100vh - 190px);
}
