.button-facebook {
  @apply text-white;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.5),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.49);
  background-image: linear-gradient(to bottom, #4192fc, #1877f2);
}

.button-google {
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.5),
    inset 0 1px 0 0 #fff;
  background-image: linear-gradient(to bottom, #fff, #e7e7e7);
  color: #636363;
}
