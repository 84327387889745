.future-wrapper {
  @apply mx-4 mb-7 mt-4 flex max-w-sm rounded-md bg-secondary uppercase text-primary;
  padding: 10px 15px 7px 15px;
  width: calc(100% - 30px);

  &-header {
    @apply text-base font-semibold;
    letter-spacing: 0.13px;
  }

  &-description {
    @apply text-xs font-semibold opacity-70;
    letter-spacing: 0.11px;
  }
}
