.button {
  transition: all 0.3s ease;
  -webkit-touch-callout: none;
  &:active {
    transform: scale(1.075);
  }
  &.suffix {
    background: black;
  }
}

.button.button-primary {
  @apply bg-gradient-primary text-primary;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.5),
    inset 0 1px 0 0 #fff;
}

.button.button-secondary {
  @apply bg-gradient-secondary;
  box-shadow:
    0 2px 5px 0 #4b006b,
    inset 0 1px 0 0 rgba(255, 255, 255, 0.19);
}

.button.button-rg {
  @apply mt-9 rounded-lg text-white;
  padding: 13px 41px;
  box-shadow:
    0 2px 5px 0 var(--primary-shade),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.19);
  background-image: linear-gradient(
    to bottom,
    var(--primary-light),
    var(--primary)
  );

  &-red {
    background-image: linear-gradient(to bottom, var(--error), #bb0f67);
  }
}

.button.button-secondary-glow {
  @apply bg-gradient-secondary;
  box-shadow:
    0 2px 5px 0 #4b006b,
    inset 0 1px 0 0 rgba(255, 255, 255, 0.29),
    inset 0 10px 20px 0 rgba(255, 255, 255, 0.1);
}

.button.button-third {
  @apply bg-gradient-tertiary;
  box-shadow:
    0 2px 5px 0 var(--primary-shadow),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.19);
}

.button.button-tiny {
  @apply px-2 py-1 text-xs;
}

.button.button-small {
  @apply px-4 py-1 text-base;
}

.button.button-large {
  @apply px-7 py-4 text-xl;
  min-width: 200px;
}

.button.disabled {
  box-shadow: none;
  opacity: 0.6;
  filter: grayscale(10%);

  &:active {
    transform: scale(1);
  }
}

.iconButton {
  @apply flex items-center justify-center;

  .icon {
    @apply ml-[-.4rem] inline-block align-middle;
  }

  .text {
    @apply ml-2 flex flex-1 items-center justify-center;
  }
}

.fullWidth {
  @apply block w-full max-w-xs xs:max-w-[15rem];
}

.progressIcon {
  @apply float-left mr-3 mt-1;
  width: 0;
  animation:
    width 0.3s normal forwards ease-in-out,
    spin 1s infinite forwards ease-in-out;
}

@keyframes width {
  0% {
    width: 0;
  }
  100% {
    width: 20px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button-animation {
  animation: animation 0.3s ease-in;
}

@keyframes animation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
