.caret {
  @apply absolute right-0.5 h-6 w-6 opacity-60;
}

.crossCircle {
  @apply absolute right-6 h-6 w-6 text-error;
}

.tickCircle {
  @apply absolute right-6 h-6 w-6 text-green-500;
}

.contact-icon {
  @apply absolute h-7 w-7;
  top: calc(50% - 14px);
  left: 12px;
  color: var(--secondary);
  &.key {
    width: 2rem;
    height: 2rem;
    top: calc(50% - 18px);
  }
}

.contact-text {
  @apply overflow-hidden text-ellipsis whitespace-nowrap text-left uppercase opacity-100;
  font-size: 20px;
  font-weight: 600;
  color: #fff;

  width: calc(100% - 100px);
  left: 54px;
  position: absolute;
}

.contact-email {
  @apply normal-case;
}

.info-card {
  @apply relative mb-6 flex h-16 cursor-pointer items-center justify-center rounded-lg bg-primary-light text-lg;
  transition: transform 0.15s ease;
  &:active {
    transform: scale(1.075);
  }
}

.rg-card {
  @apply flex w-full flex-col items-center justify-center rounded-xl text-center text-white;
  margin: 11px 19px 24px 22px;
  padding: 31px 0 38px;
  border-radius: 12px;
  box-shadow: 0 2px 78px 0 #3e0855;

  &-no-padding {
    padding-top: 0;
  }

  &-header {
    @apply text-2xl font-semibold;
    letter-spacing: 0.2px;
  }

  &-description {
    @apply mt-2.5 text-lg font-semibold opacity-60;
    padding: 0 10px;
    letter-spacing: 0.15px;
  }

  // &-image-overlay {
  //   @apply absolute h-full w-full;
  //   background-image: linear-gradient(
  //     to bottom,
  //     transparent 0%,
  //     var(--primary) 90%
  //   );
  // }

  &-image {
    @apply min-h-fit w-full rounded-t-xl;
  }
}
