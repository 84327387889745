.JackpotSlider {
    overflow: hidden;
    position: relative;
    height: 120px;
    margin: 0 auto;
    width: 100%;
  }
  
  .SliderItem {
    position: absolute;
    left: 0;
    right: 0;
    transition: transform 1s ease;
    transform: translateX(250%);
  }
  
  .activeSlide {
    transform: translateX(0);
    z-index: 8;
  }
  
  .previousSlide.left {
    transform: translateX(-250%);
    z-index: 5;
  }

  .previousSlide.right {
    transform: translateX(-250%);
    z-index: -10;
  }
  
  .nextSlide.left {
    transform: translateX(250%);
    z-index: -10;
  }

  .nextSlide.right {
    transform: translateX(250%);
    z-index: 5;
  }
