$border-style: solid 1px rgba(255, 255, 255, 0.43);

.tab-wrapper {
  li {
    @apply font-black opacity-60;
    border: $border-style;
    &:not(:last-child) {
      border-right: none;
    }

    &:first-child {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    button {
      @apply inline-block w-24 px-3 py-2 text-center text-sm;
    }
  }

  .selected {
    & + li {
      border-left: none;
    }
    @apply bg-white text-[#6e278d] opacity-100;
  }
}

.filter-wrapper {
  @apply flex;
  width: 20rem;
  border-radius: 5px;
  box-shadow:
    0 2px 5px 0 var(--primary-shade),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.19);
  background: var(--primary-light);

  label {
    @apply uppercase;
    padding-left: 16px;
    opacity: 0.42;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
  }

  div {
    &:first-child {
      button {
        border-bottom-left-radius: 5px;
      }
    }

    &:last-child {
      button {
        border-bottom-right-radius: 5px;
      }
    }
  }

  select {
    @apply py-0;
  }

  button[class='react-datepicker__close-icon'] {
    &::after {
      background-color: rgb(255 255 255 / 0.19) !important;
      padding: 0 1px 1px 2px;
    }
  }

  button[class='custom-date-picker'] {
    @apply relative w-full select-none text-left text-lg font-semibold text-white;
    padding: 0.5rem 0.75rem 0.75rem 14px;
    background-image: linear-gradient(
      to bottom,
      var(--primary-light),
      var(--primary)
    );
    border: none;
    box-shadow: none !important;
    outline: none !important;

    &[type='date']::before {
      @apply absolute opacity-50;
      color: #ffffff;
      content: attr(placeholder);
    }
    &[type='date']:focus::before {
      content: '' !important;
    }
    &::-webkit-date-and-time-value {
      text-align: left;
    }

    &::placeholder {
      color: white;
      opacity: 0.5;
    }
  }

  .separator {
    opacity: 0.43;
    border: solid 1px #915ba8;
  }
}

.summary-wrapper {
  @apply relative mt-2.5;

  .summary-btn {
    @apply cursor-pointer text-center uppercase text-white underline;
    opacity: 0.42;
    font-size: 18px;
    font-weight: bold;
  }

  .summary-btns-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 320px;
    > div {
      width: 157px;
      margin-bottom: 6px;
      padding: 18px 33px 13px 31px;
      border-radius: 4px;
      background-color: rgba(230, 228, 228, 0.09);
      &:nth-child(even) {
        margin-left: 6px;
      }

      .header {
        @apply text-center text-2xl font-bold text-white;
        margin-bottom: 7px;
      }
      .description {
        @apply text-center text-base font-bold uppercase opacity-40;
        color: rgba(255, 255, 255, 0.71);
      }
    }
    &--fullDiv {
      width: 100% !important;
    }
  }
}
