.hasBadge {
  position: relative;
  mask: radial-gradient(circle 15px at top right, transparent 97%, #000) 0 3px;
}

.iconButton {
  max-width: 45px;
  max-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.balance {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: 18px;
}

.ping {
  position: absolute;
  inset: 0;
  height: 18px;
  width: 18px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  background-color: var(--secondary);
  animation: ping 3.5s linear infinite;
}

@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  30% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
