.button {
  @apply bg-black/50 px-4 py-2 text-base uppercase text-white/60;
  border-radius: 10rem;
  transition: transform 0.15s ease;
  -webkit-touch-callout: none;
  &:active {
    transform: scale(1.075);
  }
}

.button-variant-secondary {
  @apply bg-secondary text-primary;
}

.button-size-tiny {
  @apply px-2 py-1 text-xs;
}

.button-size-small {
  @apply px-3 py-1 text-sm;
}

.button-size-large {
  @apply text-xl font-bold;
}

.disabled {
  opacity: 0.35;
  cursor: default;
}

.round {
  border-radius: 10rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
