.future-icon-wrapper {
  @apply relative max-h-4 w-4;
}

.clock {
  @apply absolute left-0 top-px h-4 w-4;
  circle {
    @apply hidden;
  }
}

.arrow {
  @apply absolute h-5 w-5;
  transform: rotate(-106deg);
  top: -2px;
  left: -2px;
}
