.checkboxWrapper {
  $duration: 0.2s;
  $style: ease-out;
  .Box {
    @apply relative mr-4 rounded-2xl bg-primary-dark;
    transition: background-color $duration $style;
    width: 60px;
    height: 33px;
    padding: 3px 4px 4px 30px;
  }

  .Oval {
    @apply absolute rounded-full bg-primary-light;
    transition: $duration $style;
    width: 26px;
    height: 26px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
    transform: translateX(-27px);
  }

  .checked {
    .Box {
      @apply bg-secondary;
    }

    .Oval {
      @apply translate-x-0 bg-white;
    }
  }
}
