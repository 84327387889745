.button {
  @apply flex min-h-[80px] select-none items-center px-6 py-2 font-bold uppercase;
}

.dot {
  @apply mr-4 h-4 w-4 rounded-full;
}
.dot--primary {
  @apply bg-white;
  opacity: 0.45;
}
.dot--secondary {
  @apply bg-secondary;
}

.dot--unchecked {
  @apply bg-transparent;
}

.description {
  @apply text-sm;
}
.description--primary {
  color: rgba(151, 90, 0, 0.66);
}
.description--secondary {
  @apply text-white/70;
}

.button--listButton {
  @apply h-20 w-full bg-white font-bold text-white;
  letter-spacing: 0.18px;

  &:first-of-type {
    @apply rounded-t-md;
  }
  &:last-of-type {
    @apply rounded-b-md;
  }
}
.button--animate {
  transition: transform 0.15s ease;
  &:active {
    transform: scale(1.075);
  }
}

.button-primary {
  @apply bg-gradient-to-b from-secondary to-secondary-dark;
  box-shadow:
    0 2px 12px 0 rgba(0, 0, 0, 0.5),
    inset 0 1px 0 0 #fff;
  color: var(--primary);
}

.button-secondary {
  @apply bg-gradient-to-b from-primary-light to-primary;
  box-shadow:
    0 2px 5px 0 var(--primary-shade),
    inset 0 1px 0 0 rgb(255 255 255 / 19%);
}

.button-third {
  @apply bg-gradient-to-b from-primary-light to-primary;
  box-shadow:
    0 2px 5px 0 var(--primary-shade),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.19);
}
