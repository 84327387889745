.table {
  min-height: 583px;

  &-header {
    @apply whitespace-nowrap px-4 py-2 text-left text-lg font-bold opacity-50;
  }

  &-data {
    @apply whitespace-nowrap px-4 py-2 text-left text-base font-semibold text-white;
  }
}

.tableContainer {
  @apply w-full;
  position: relative;
  margin: 0 -32px;
  border-top: solid 1px rgba(255, 255, 255, 0.13);
  &:before {
    content: '';
    position: absolute;
    top: 8px;
    right: -1px;
    width: 20px;
    bottom: 68px;
  }
  @media (min-width: 980px) {
    > div:first-child {
      min-height: 640px;
    }
  }
}
