.tabsRoot {
  @apply z-50 flex flex-1 select-none justify-center border-white/20 px-3 ;

  .tabButton {
    @apply mb-1 flex flex-1 translate-y-[3px] items-center justify-center border-b border-b-primary-borderGrey px-6 py-3 text-center text-base font-semibold uppercase tracking-wide opacity-80 md:max-w-xs;
  }

  .tabButton.selected {
    @apply border-b border-solid border-secondary fill-secondary font-bold text-secondary opacity-100;
  }

  .badge {
    @apply ml-2 flex h-5 w-5 items-center justify-center rounded-full bg-white text-sm text-primary;
  }

  .icon {
    @apply mr-2;
  }

  .selected .badge {
    @apply bg-secondary;
  }
}
