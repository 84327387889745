.verifyInput {
  input {
    letter-spacing: 6px;
    text-align: center;
    padding: 0;
    @apply shadow-3xl;
  }

  input::placeholder {
    @apply text-xs text-stone-300;
    opacity: 0.65;
    letter-spacing: 7px;
    text-align: center;
    padding-top: 0.5rem;
    padding-left: 0.6rem;
  }
}

.havingIssuesHeader {
  @apply flex items-center text-2xl font-semibold uppercase;
  letter-spacing: 0.22px;
  text-align: center;
  color: #fff;
  margin: 0 auto;
}

.havingIssuesBackBtn {
  @apply absolute left-0 top-0 m-4 flex h-11 w-11 cursor-pointer items-center justify-center;
  background-color: rgba(255, 255, 255, 0.09);
  border-radius: 50%;
}

.havingIssues {
  @apply mb-5 h-12 w-80 cursor-pointer list-none uppercase p-0;
  box-shadow:
    0 2px 5px 0 var(--primary-shade),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.19);
  background: linear-gradient(to bottom, var(--primary-light), var(--primary));
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  text-transform: unset !important;
}

.limitInput {
  input {
    @apply text-2xl font-semibold;
    letter-spacing: 0.18px;
    text-align: left;
    margin-bottom: 10px;
  }

  label {
    color: white;
  }
}
